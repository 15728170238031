<template>
  <div class="logo">
    <router-link to="/" @click.native="logoClick">
      <svg v-if="!mini" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 309 22" width="309" height="22">
        <g :style="{fill: `rgb(${theme})`}">
          <path d="M22.8,21H7H6.1c-0.8,0-1.5-0.1-2-0.2c-0.5-0.1-0.7-0.3-0.7-0.6c0-0.4,0.5-0.7,1.4-0.8
		c0.8-0.1,1.2-0.2,1.4-0.2c0.1,0,0.3-0.1,0.4-0.2C6.9,18.9,7,18.7,7,18.3V3.7c0-0.3-0.1-0.6-0.4-0.7C6.5,2.8,6.3,2.8,6.2,2.8
		c-0.1,0-0.6-0.1-1.4-0.2C3.9,2.4,3.4,2.2,3.4,1.8c0-0.2,0.2-0.4,0.7-0.6C4.6,1.1,5.2,1,6.1,1H7h15.7h0.9c0.8,0,1.5,0.1,2,0.2
		s0.7,0.3,0.7,0.6c0,0.4-0.5,0.7-1.4,0.8c-0.8,0.1-1.2,0.2-1.4,0.2s-0.3,0.1-0.4,0.2c-0.3,0.2-0.4,0.4-0.4,0.7v14.7
		c0,0.3,0.1,0.6,0.4,0.7c0.1,0.1,0.3,0.1,0.4,0.2s0.6,0.1,1.4,0.2c0.9,0.1,1.4,0.4,1.4,0.8c0,0.2-0.2,0.4-0.7,0.6
		c-0.5,0.1-1.1,0.2-2,0.2H22.8z"/>
          <path d="M37.3,6.2L41,8.7v7.9c0,0.7,0.3,1.2,0.8,1.7c0.6,0.4,1.4,0.8,2.6,1.1l1.1,0.2c0.6,0.1,0.9,0.4,0.9,0.7
		c0,0.2-0.2,0.4-0.7,0.6c-0.4,0.1-1.2,0.2-2.3,0.2h-8.2c-2.1,0-3.2-0.3-3.2-0.8c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.7-0.2,1.5-0.4
		c2.3-0.5,3.4-1.5,3.4-2.8V6.2z M52.1,1l18.8,13.5V21h-9.6l-24-17.1c-0.5-0.4-0.9-0.6-1.3-0.8c-0.3-0.1-0.8-0.3-1.3-0.3l-1.3-0.2
		c-0.4,0-0.6-0.1-0.8-0.3S32.2,2,32.2,1.9c0-0.2,0.1-0.3,0.3-0.5s0.5-0.2,0.8-0.3C33.8,1,34.9,1,36.6,1H52.1z M70.9,12.2l-3.7-2.6
		V5.5c0-0.8-0.3-1.4-0.9-1.9c-0.6-0.4-1.6-0.8-3.1-1.1c-0.5-0.1-0.8-0.2-1-0.3C62,2.1,61.9,2,61.9,1.8c0-0.3,0.2-0.5,0.6-0.6
		c0.4-0.1,1.1-0.2,2-0.2h8.8c1.2,0,2,0.1,2.4,0.2c0.4,0.2,0.6,0.3,0.6,0.6c0,0.2-0.1,0.4-0.3,0.5s-0.8,0.2-1.6,0.4
		c-1.2,0.3-2.2,0.7-2.8,1.2c-0.4,0.4-0.7,0.9-0.7,1.6V12.2z"/>
          <path d="M100.1,0.6v1.8c-1.4,0.1-2.4,0.3-3.1,0.8s-1,1-1,1.8v12.1v0.6c0,0.2,0.1,0.5,0.3,0.7
		c0.2,0.3,0.5,0.5,0.9,0.6c0.4,0.2,0.8,0.3,1.2,0.4c0.4,0.1,1,0.1,1.7,0.2v1.8c-4.6,0-8.5-0.5-11.9-1.5c-3-0.9-5.2-2-6.7-3.5
		c-1.5-1.5-2.3-3.2-2.3-5.2c0-1.5,0.5-2.8,1.5-4.1c1-1.3,2.4-2.4,4.3-3.3c1.9-0.9,4.1-1.7,6.8-2.3c2.6-0.6,5.1-0.9,7.6-0.9
		L100.1,0.6z M102.9,2.4V0.6c1.2,0.1,2.1,0.2,2.8,0.2c0.7,0.1,2,0.3,3.9,0.6c1.2,0.2,2,0.3,2.4,0.3c0.7,0,1.2-0.1,1.6-0.4L114,1
		c0.3-0.2,0.8-0.4,1.6-0.4c0.6,0,1.1,0.1,1.5,0.2c0.3,0.2,0.5,0.4,0.5,0.7v6.1c0,0.4-0.2,0.7-0.6,0.8c-0.4,0.2-0.9,0.3-1.4,0.3
		c-0.6,0-1-0.1-1.4-0.2c-0.3-0.2-0.6-0.4-0.8-0.7c-0.5-0.8-1-1.4-1.4-1.8c-1-0.9-2.2-1.7-3.5-2.3c-0.9-0.4-1.7-0.7-2.6-0.9
		C104.9,2.6,103.9,2.5,102.9,2.4z M103,21.4v-1.8c1.8-0.1,3.8-0.3,5.8-0.9c1.1-0.3,2-0.7,2.9-1.1s1.5-1,1.9-1.6
		c0.2-0.3,0.5-0.5,0.8-0.7c0.3-0.1,0.7-0.2,1.3-0.2c0.6,0,1,0.1,1.4,0.3c0.4,0.2,0.6,0.4,0.6,0.7c0,0.4-0.4,1-1.2,1.6
		s-1.9,1.2-3.1,1.7c-1.6,0.6-3.1,1.1-4.8,1.4C106.8,21.2,105,21.4,103,21.4z"/>
          <path d="M125.9,15.2V3.7c0-0.3-0.1-0.6-0.4-0.7c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.6-0.1-1.4-0.2
		c-0.9-0.1-1.4-0.4-1.4-0.8c0-0.3,0.2-0.4,0.7-0.6s1.1-0.2,2-0.2h0.9h15.7h0.9l1.3,0.1c0.4,0,0.7,0.1,1,0.3c0.3,0.1,0.4,0.3,0.4,0.5
		c0,0.4-0.5,0.6-1.4,0.8c-0.8,0.1-1.2,0.2-1.4,0.2s-0.3,0.1-0.4,0.2c-0.3,0.2-0.4,0.4-0.4,0.7V16c0,0.8,0.1,1.4,0.2,1.7
		s0.3,0.6,0.7,0.9c0.3,0.2,0.6,0.4,1,0.5c0.4,0.1,1.1,0.2,2.2,0.4v1.9h-0.8c-3.1,0-5.6-0.1-7.6-0.3c-2.4-0.3-4.5-0.7-6.2-1.2
		c-1.8-0.5-3.1-1.1-3.8-1.7C126.3,17.5,125.9,16.5,125.9,15.2z M148.8,21.4v-1.9c2.4-0.1,4.3-0.5,5.9-1.2c1.8-0.8,2.7-1.9,2.7-3.3
		V5.4c0-1.3-1.2-2.3-3.7-2.8c-0.7-0.2-1.2-0.3-1.4-0.4c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.3,0.2-0.4,0.7-0.5c0.4-0.1,1.2-0.2,2.2-0.2h8.6
		c0.7,0,1.2,0,1.5,0c0.2,0,0.5,0.1,0.7,0.1c0.6,0.1,0.9,0.3,0.9,0.6c0,0.2-0.2,0.4-0.6,0.5c-0.1,0-0.6,0.2-1.5,0.4
		c-1,0.2-1.8,0.6-2.4,1.1s-0.9,1-0.9,1.6V15c0,1.6-0.9,3-2.6,4c-1.3,0.8-3.1,1.4-5.5,1.9C151.9,21.1,150.5,21.3,148.8,21.4z"/>
          <path d="M177,6.2l3.7,2.6v7.9c0,0.7,0.3,1.2,0.8,1.7c0.6,0.4,1.4,0.8,2.6,1.1l1.1,0.2c0.6,0.1,0.9,0.4,0.9,0.7
		c0,0.2-0.2,0.4-0.7,0.6c-0.4,0.1-1.2,0.2-2.3,0.2H175c-2.1,0-3.2-0.3-3.2-0.8c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.7-0.2,1.5-0.4
		c2.3-0.5,3.4-1.5,3.4-2.8V6.2z M191.8,1l18.8,13.5V21H201L177,3.9c-0.5-0.4-0.9-0.6-1.3-0.8c-0.3-0.1-0.8-0.3-1.3-0.3l-1.3-0.2
		c-0.4,0-0.6-0.1-0.8-0.3s-0.3-0.3-0.3-0.4c0-0.2,0.1-0.3,0.3-0.5s0.5-0.2,0.8-0.3c0.4-0.1,1.5-0.1,3.2-0.1H191.8z M210.6,12.2
		l-3.7-2.6V5.5c0-0.8-0.3-1.4-0.9-1.9c-0.6-0.4-1.6-0.8-3.1-1.1c-0.5-0.1-0.8-0.2-1-0.3s-0.3-0.3-0.3-0.4c0-0.3,0.2-0.5,0.6-0.6
		c0.4-0.1,1.1-0.2,2-0.2h8.8c1.2,0,2,0.1,2.4,0.2c0.4,0.2,0.6,0.3,0.6,0.6c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.8,0.2-1.6,0.4
		c-1.2,0.3-2.2,0.7-2.8,1.2c-0.4,0.4-0.7,0.9-0.7,1.6V12.2z"/>
          <path d="M225.6,6.2l3.7,2.6v7.9c0,0.7,0.3,1.2,0.8,1.7c0.6,0.4,1.4,0.8,2.6,1.1l1.1,0.2c0.6,0.1,0.9,0.4,0.9,0.7
		c0,0.2-0.2,0.4-0.7,0.6c-0.4,0.1-1.2,0.2-2.3,0.2h-8.2c-2.1,0-3.2-0.3-3.2-0.8c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.7-0.2,1.5-0.4
		c2.3-0.5,3.4-1.5,3.4-2.8V6.2z M240.4,1l18.8,13.5V21h-9.6l-24-17.1c-0.5-0.4-0.9-0.6-1.3-0.8c-0.3-0.1-0.8-0.3-1.3-0.3l-1.3-0.2
		c-0.4,0-0.6-0.1-0.8-0.3s-0.3-0.3-0.3-0.4c0-0.2,0.1-0.3,0.3-0.5s0.5-0.2,0.8-0.3C222,1,223.1,1,224.9,1H240.4z M259.1,12.2
		l-3.7-2.6V5.5c0-0.8-0.3-1.4-0.9-1.9c-0.6-0.4-1.6-0.8-3.1-1.1c-0.5-0.1-0.8-0.2-1-0.3s-0.3-0.3-0.3-0.4c0-0.3,0.2-0.5,0.6-0.6
		c0.4-0.1,1.1-0.2,2-0.2h8.8c1.2,0,2,0.1,2.4,0.2c0.4,0.2,0.6,0.3,0.6,0.6c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.8,0.2-1.6,0.4
		c-1.2,0.3-2.2,0.7-2.8,1.2c-0.4,0.4-0.7,0.9-0.7,1.6V12.2z"/>
          <path d="M288.2,1v20h-15.7h-0.9c-0.8,0-1.5-0.1-2-0.2c-0.5-0.1-0.7-0.3-0.7-0.6c0-0.4,0.5-0.7,1.4-0.8
		c0.8-0.1,1.2-0.2,1.4-0.2c0.1,0,0.3-0.1,0.4-0.2c0.3-0.2,0.4-0.4,0.4-0.7V3.7c0-0.3-0.1-0.6-0.4-0.7c-0.1-0.1-0.3-0.1-0.4-0.2
		c-0.1,0-0.6-0.1-1.4-0.2c-0.9-0.1-1.4-0.4-1.4-0.8c0-0.2,0.2-0.4,0.7-0.6s1.1-0.2,2-0.2h0.9H288.2z M291.2,11.7V10
		c1.4-0.3,2.4-0.6,3-0.9c0.7-0.3,1.1-0.8,1.4-1.3c0.2-0.4,0.4-0.6,0.7-0.8s0.7-0.2,1.3-0.2c0.8,0,1.3,0.1,1.6,0.4
		c0.2,0.1,0.2,0.4,0.2,0.9v5.6c0,0.5-0.1,0.8-0.4,1s-0.7,0.3-1.4,0.3c-0.5,0-1-0.1-1.3-0.2s-0.6-0.4-0.7-0.7c-0.4-0.7-1-1.2-1.7-1.6
		S292.3,11.8,291.2,11.7z M291.3,1h14.2v5.2c0,0.3-0.1,0.5-0.4,0.7s-0.7,0.2-1.3,0.2c-0.5,0-0.9,0-1.1-0.1c-0.2-0.1-0.5-0.3-0.7-0.5
		c-0.9-1.1-2.2-2-3.9-2.6s-4-1-6.7-1.1V1z M291.4,21v-1.6c1.5-0.1,2.8-0.2,3.8-0.4c1-0.2,2-0.4,3-0.8c1-0.4,1.8-0.7,2.4-1.1
		c0.6-0.4,1.2-0.9,1.7-1.5c0.3-0.3,0.5-0.5,0.8-0.6c0.3-0.1,0.7-0.2,1.2-0.2c0.7,0,1.1,0.1,1.4,0.2c0.3,0.2,0.4,0.4,0.4,0.8V21
		H291.4z"/>
        </g>
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186 14" width="186" height="14">
        <g :style="{fill: `rgb(${theme})`}">
          <path d="M13.7,13.7H4.2H3.7c-0.5,0-0.9,0-1.2-0.1c-0.3-0.1-0.4-0.2-0.4-0.4c0-0.3,0.3-0.4,0.8-0.5
		c0.5-0.1,0.7-0.1,0.8-0.1s0.2-0.1,0.2-0.1c0.2-0.1,0.3-0.3,0.3-0.5V2.7c0-0.2-0.1-0.4-0.3-0.5C3.9,2.2,3.8,2.1,3.8,2.1
		C3.7,2.1,3.4,2.1,2.9,2C2.4,1.9,2.1,1.7,2.1,1.5c0-0.2,0.1-0.3,0.4-0.4C2.8,1.1,3.2,1,3.7,1h0.6h9.5h0.6c0.5,0,0.9,0,1.2,0.1
		c0.3,0.1,0.4,0.2,0.4,0.4c0,0.3-0.3,0.4-0.8,0.5c-0.5,0.1-0.7,0.1-0.8,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0.1-0.3,0.3-0.3,0.5V12
		c0,0.2,0.1,0.4,0.3,0.5c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.4,0.1,0.8,0.1c0.6,0.1,0.8,0.2,0.8,0.5c0,0.2-0.1,0.3-0.4,0.4
		c-0.3,0.1-0.7,0.1-1.2,0.1H13.7z"/>
          <path d="M22.5,4.3l2.3,1.6v5c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.9,0.5,1.6,0.7l0.6,0.2c0.4,0.1,0.6,0.2,0.6,0.4
		c0,0.1-0.1,0.3-0.4,0.4c-0.2,0.1-0.7,0.1-1.4,0.1h-4.9c-1.3,0-1.9-0.2-1.9-0.5c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.4-0.2,0.9-0.3
		c1.4-0.3,2-0.9,2-1.8V4.3z M31.4,1l11.3,8.6v4.1h-5.8L22.4,2.8c-0.3-0.2-0.6-0.4-0.8-0.5c-0.2-0.1-0.5-0.2-0.8-0.2L20.1,2
		c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.2,0.5-0.2C20.3,1,21,1,22.1,1H31.4z
		 M42.7,8.1l-2.3-1.7V3.9c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-1-0.5-1.8-0.7c-0.3-0.1-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.2-0.2-0.3
		c0-0.2,0.1-0.3,0.4-0.4C37.9,1,38.3,1,38.8,1h5.3c0.7,0,1.2,0,1.4,0.1c0.2,0.1,0.4,0.2,0.4,0.4c0,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.5,0.2-1,0.3c-0.8,0.2-1.3,0.4-1.7,0.8c-0.3,0.3-0.4,0.6-0.4,1V8.1z"/>
          <path d="M60.2,0.8v1.1c-0.8,0.1-1.5,0.2-1.9,0.5C58,2.6,57.8,3,57.8,3.5v7.7v0.4c0,0.1,0.1,0.3,0.2,0.4
		c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.5,0.2,0.7,0.3c0.3,0.1,0.6,0.1,1,0.1v1.1c-2.7,0-5.1-0.3-7.2-0.9c-1.8-0.6-3.1-1.3-4-2.2
		c-0.9-0.9-1.4-2-1.4-3.3c0-0.9,0.3-1.8,0.9-2.6C49.2,4,50,3.3,51.1,2.7c1.1-0.6,2.5-1.1,4.1-1.4c1.6-0.4,3.1-0.5,4.6-0.5L60.2,0.8z
		 M61.9,1.9V0.8c0.7,0,1.3,0.1,1.7,0.2C64,1,64.8,1.1,66,1.3c0.7,0.1,1.2,0.2,1.4,0.2c0.4,0,0.7-0.1,0.9-0.2L68.6,1
		c0.2-0.1,0.5-0.2,1-0.2c0.4,0,0.7,0,0.9,0.1c0.2,0.1,0.3,0.2,0.3,0.4v3.9c0,0.2-0.1,0.4-0.4,0.5c-0.3,0.1-0.5,0.2-0.9,0.2
		c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.4-0.3-0.5-0.5c-0.3-0.5-0.6-0.9-0.9-1.2c-0.6-0.6-1.3-1.1-2.1-1.4c-0.5-0.2-1-0.4-1.6-0.6
		C63.1,2,62.6,1.9,61.9,1.9z M62,13.9v-1.1c1.1,0,2.3-0.2,3.5-0.5c0.7-0.2,1.2-0.4,1.7-0.7c0.5-0.3,0.9-0.6,1.1-1
		c0.1-0.2,0.3-0.3,0.5-0.4C69,10,69.3,10,69.6,10c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.3,0.3,0.3,0.4c0,0.3-0.2,0.6-0.7,1
		c-0.5,0.4-1.1,0.8-1.9,1.1c-0.9,0.4-1.9,0.7-2.9,0.9C64.3,13.8,63.2,13.9,62,13.9z"/>
          <path d="M75.8,10V2.7c0-0.2-0.1-0.4-0.3-0.5c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.4-0.1-0.8-0.1
		c-0.6-0.1-0.9-0.2-0.9-0.5c0-0.2,0.1-0.3,0.4-0.4C74.3,1.1,74.7,1,75.2,1h0.6h9.5h0.6l0.8,0c0.2,0,0.4,0.1,0.6,0.2
		c0.2,0.1,0.2,0.2,0.2,0.3c0,0.2-0.3,0.4-0.9,0.5c-0.5,0.1-0.7,0.1-0.8,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0.1-0.3,0.3-0.3,0.5v7.8
		c0,0.5,0,0.9,0.1,1.1c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0.1,0.7,0.1,1.3,0.2v1.2h-0.5c-1.8,0-3.4-0.1-4.6-0.2
		c-1.4-0.2-2.7-0.4-3.8-0.7c-1.1-0.3-1.8-0.7-2.3-1.1C76,11.4,75.8,10.8,75.8,10z M89.6,13.9v-1.2c1.4-0.1,2.6-0.3,3.5-0.8
		c1.1-0.5,1.6-1.2,1.6-2.1v-6c0-0.8-0.7-1.4-2.2-1.8c-0.4-0.1-0.7-0.2-0.9-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.2,0.1-0.3,0.4-0.3
		C92.1,1,92.6,1,93.2,1h5.2c0.4,0,0.7,0,0.9,0c0.1,0,0.3,0,0.4,0.1c0.4,0.1,0.6,0.2,0.6,0.4c0,0.1-0.1,0.3-0.3,0.3
		c-0.1,0-0.4,0.1-0.9,0.3c-0.6,0.1-1.1,0.4-1.5,0.7c-0.4,0.3-0.5,0.7-0.5,1v6.1c0,1-0.5,1.9-1.6,2.6c-0.8,0.5-1.9,0.9-3.3,1.2
		C91.4,13.7,90.6,13.9,89.6,13.9z"/>
          <path d="M106.6,4.3l2.3,1.6v5c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.9,0.5,1.6,0.7l0.6,0.2c0.4,0.1,0.6,0.2,0.6,0.4
		c0,0.1-0.1,0.3-0.4,0.4c-0.2,0.1-0.7,0.1-1.4,0.1h-4.9c-1.3,0-1.9-0.2-1.9-0.5c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.4-0.2,0.9-0.3
		c1.4-0.3,2-0.9,2-1.8V4.3z M115.5,1l11.3,8.6v4.1H121L106.5,2.8c-0.3-0.2-0.6-0.4-0.8-0.5s-0.5-0.2-0.8-0.2L104.2,2
		c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.9-0.1,2-0.1H115.5z
		 M126.8,8.1l-2.3-1.7V3.9c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-1-0.5-1.8-0.7c-0.3-0.1-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.2-0.2-0.3
		c0-0.2,0.1-0.3,0.4-0.4C122,1,122.4,1,122.9,1h5.3c0.7,0,1.2,0,1.4,0.1c0.2,0.1,0.4,0.2,0.4,0.4c0,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.5,0.2-1,0.3c-0.8,0.2-1.3,0.4-1.7,0.8c-0.3,0.3-0.4,0.6-0.4,1V8.1z"/>
          <path d="M135.8,4.3l2.3,1.6v5c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.9,0.5,1.6,0.7l0.6,0.2c0.4,0.1,0.6,0.2,0.6,0.4
		c0,0.1-0.1,0.3-0.4,0.4c-0.2,0.1-0.7,0.1-1.4,0.1h-4.9c-1.3,0-1.9-0.2-1.9-0.5c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.4-0.2,0.9-0.3
		c1.4-0.3,2-0.9,2-1.8V4.3z M144.7,1L156,9.6v4.1h-5.8L135.7,2.8c-0.3-0.2-0.6-0.4-0.8-0.5s-0.5-0.2-0.8-0.2L133.4,2
		c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.9-0.1,2-0.1H144.7z
		 M156,8.1l-2.3-1.7V3.9c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-1-0.5-1.8-0.7c-0.3-0.1-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.2-0.2-0.3
		c0-0.2,0.1-0.3,0.4-0.4c0.2-0.1,0.6-0.1,1.2-0.1h5.3c0.7,0,1.2,0,1.4,0.1c0.2,0.1,0.4,0.2,0.4,0.4c0,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.5,0.2-1,0.3c-0.8,0.2-1.3,0.4-1.7,0.8c-0.3,0.3-0.4,0.6-0.4,1V8.1z"/>
          <path d="M173.5,1v12.7H164h-0.6c-0.5,0-0.9,0-1.2-0.1c-0.3-0.1-0.4-0.2-0.4-0.4c0-0.3,0.3-0.4,0.8-0.5
		c0.5-0.1,0.7-0.1,0.8-0.1c0.1,0,0.2-0.1,0.2-0.1c0.2-0.1,0.3-0.3,0.3-0.5V2.7c0-0.2-0.1-0.4-0.3-0.5c-0.1-0.1-0.2-0.1-0.2-0.1
		c-0.1,0-0.4-0.1-0.8-0.1c-0.6-0.1-0.8-0.2-0.8-0.5c0-0.2,0.1-0.3,0.4-0.4c0.3-0.1,0.7-0.1,1.2-0.1h0.6H173.5z M175.2,7.8V6.7
		c0.8-0.2,1.4-0.4,1.8-0.6s0.7-0.5,0.9-0.8c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.4-0.1,0.8-0.1c0.5,0,0.8,0.1,0.9,0.2
		c0.1,0.1,0.1,0.3,0.1,0.5V9c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.4,0.2-0.8,0.2c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.3-0.2-0.4-0.4
		c-0.2-0.4-0.6-0.8-1-1C176.5,8,175.9,7.8,175.2,7.8z M175.3,1h8.6v3.3c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.4,0.1-0.8,0.1
		c-0.3,0-0.5,0-0.7-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.5-0.7-1.3-1.3-2.4-1.6c-1-0.4-2.4-0.6-4-0.7V1z M175.4,13.7v-1
		c0.9-0.1,1.7-0.1,2.3-0.3c0.6-0.1,1.2-0.3,1.8-0.5c0.6-0.2,1.1-0.5,1.5-0.7s0.7-0.6,1-1c0.2-0.2,0.3-0.3,0.5-0.4
		c0.2-0.1,0.4-0.1,0.7-0.1c0.4,0,0.7,0.1,0.8,0.2c0.2,0.1,0.2,0.3,0.2,0.5v3.3H175.4z"/>
        </g>
      </svg>
    </router-link>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'Logo',
  props: {
    mini: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#dbdbdb'
    }
  },
  computed: {
    ...mapState('common', {
      theme: state => state.theme
    })
  },
  methods: {
    logoClick() {
      this.$root.$emit('toHome')
      this.$root.$emit('closeMobileMenu')
    }
  }
}
</script>

<style lang="sass" scoped>
.logo
  width: min-content
</style>