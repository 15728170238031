var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header indent",class:{active: _vm.mobileMenu}},[_c('div',{staticClass:"header__links"},_vm._l((_vm.links),function(item,index){return _c('router-link',{key:index,staticClass:"header__link",style:({color: item.active ? ("rgb(" + _vm.theme + ")"): ''}),attrs:{"to":item.to,"exact":""},nativeOn:{"click":function($event){return _vm.checkNavLinks(false, index)}}},[_vm._v(" "+_vm._s(item.title[_vm.lang])+" ")])}),1),_c('div',{staticClass:"header__menu",class:{active: _vm.mobileMenu},on:{"click":_vm.menuClick}},[_c('div',{staticClass:"line",style:({background: ("rgb(" + _vm.theme + ")")})}),_c('div',{staticClass:"line",style:({background: ("rgb(" + _vm.theme + ")")})}),_c('div',{staticClass:"line",style:({background: ("rgb(" + _vm.theme + ")")})})]),_c('div',{staticClass:"mobile-menu"},[_c('transition',{attrs:{"name":"mobileMenu"}},[(_vm.mobileMenu)?_c('div',{staticClass:"header__menu-list indent"},[_c('div',{staticClass:"header__menu-items"},_vm._l((_vm.links),function(item,index){return _c('router-link',{key:index,staticClass:"header__menu-item",style:({
               color: item.active ? ("rgb(" + _vm.theme + ")"): '',
               borderBottom: item.active ? ("1px solid rgba(" + _vm.theme + ", .5)"): '1px solid transparent',
               background: item.active ? ("rgba(" + _vm.theme + ", .07)") : ''
             }),attrs:{"to":item.to,"exact":""},nativeOn:{"click":function($event){return _vm.checkNavLinks(true, index)}}},[_vm._v(" "+_vm._s(item.title[_vm.lang])+" ")])}),1)]):_vm._e()])],1),_c('div',{staticClass:"header__right"},[_c('div',{staticClass:"header__email"},[_vm._v(" info"),_c('span',{style:({color: ("rgba(" + _vm.theme + ", .7)")})},[_vm._v("@")]),_vm._v("incunne.ru ")]),_c('div',{staticClass:"header__colors"},_vm._l((_vm.colors),function(item,index){return _c('div',{key:index,staticClass:"header__color",style:({
        background: item.active? ("rgba(" + (item.val) + ", .7)") : 'transparent',
        outline: ("1px solid rgba(" + (item.val) + ", .7)")
      }),on:{"click":function($event){return _vm.setTheme(index)}}})}),0),_c('div',{staticClass:"header__settings"},[_c('span',{staticClass:"header__language",style:({color: ("rgb(" + _vm.theme + ")")}),on:{"click":_vm.changeLanguage}},[_vm._v(" "+_vm._s(_vm.lang.toUpperCase())+" ")]),_c('SVGIcon',{staticClass:"header__info",attrs:{"width":"auto","height":"16","iconName":"info2","color":_vm.isTooltip === 'on' ? ("rgba(" + _vm.theme + ", .7)") : 'rgba(219,219,219, .5)'},on:{"click":_vm.changeTooltipStatus}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }